import React, {useContext, useEffect, useState} from 'react';
import firebase from '../../firebase';
import MenuItem from "./MenuItem";
import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import {FoodItem, FoodItemGroup} from "../../pages/FoodPage";
import {useNavigate} from "@reach/router";
import classes from './FoodSection.module.css';
import AppConfigContext from "../../utils/AppConfigContext";


const FoodSection = () => {

  const {showFrame} = useContext(AppConfigContext);
  const navigate = useNavigate();
  const [foodItems, setFoodItems] = useState<FoodItemGroup[]>([])

  useEffect(() => {
    firebase.database().ref(`/conferences/${process.env.REACT_APP_ORGANIZATION_CODE}/foodMenu`).once('value').then(snapshot => {
      const foodItems = flow(groupBy('type'), values, map(items => ({
        group: items[0].type,
        items: items
      } as FoodItemGroup)))(Object.entries(snapshot.val()).map((item: [string, any]) => ({id: item[0], ...item[1]} as FoodItem))) as FoodItemGroup[];
      setFoodItems(foodItems);
    }).catch(e => console.error(e));
  }, [])

  return (
    <div className={classes.FoodItems} style={ showFrame? {justifyContent:'flex-start'}: {}}>
      {foodItems.map((item, index) =>
        <MenuItem key={index} item={item.items[0]} small onClick={() => navigate('/food-order', { state: { menuItem: item.items[0] }})}/>
      )}
      <MenuItem
        onClick={() => navigate('/food-items')}
        item={{
          title: 'View more →',
          image: 'https://storage.googleapis.com/sava-19e24.appspot.com//@unahotelsdemo/foodMenu/-LwJZJcjRkdwkSea3g5v/7594dd9d-9633-4bdc-8c85-103fac94fab3.jpeg'
        }} small/>
    </div>
  )
}

export default FoodSection;
