// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
import 'firebase/storage';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAFhip9CLEJHkmodzujnhHQQEuo02z9gW8",
  authDomain: "avery-prod.firebaseapp.com",
  databaseURL: "https://avery-prod.firebaseio.com",
  projectId: "avery-prod",
  storageBucket: "avery-prod.appspot.com",
  messagingSenderId: "739814882402",
  appId: "1:739814882402:web:0d031c8c24fad27522a53b",
  measurementId: "G-MGDDLE38NE"
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

// firebase
//   .firestore()
//   .clearPersistence()
//   .catch(error => {
//     console.error('Could not clear persistence:', error.code);
//   });

// firebase
//   .firestore()
//   .enablePersistence({
//     synchronizeTabs: true,
//   })
//   .then(() => {
//     console.log('Enabled offline sync');
//   })
//   .catch(function(err) {
//     console.warn('Failed to enable firestore persistence', err);
//   });

export default firebase;
