import React, {Fragment} from 'react';
import {PropsWithChildren, ReactNode} from "react";

import classes from './Popup.module.css';

interface PopupProps {
  show: boolean;
  title?: string;
  actionBar?: ReactNode
  onClose?: () => void;
  style?: any;
}

const Popup = (props: PropsWithChildren<PopupProps>) => {
  const {children, title, onClose, style, show} = props;

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (onClose) {
      onClose();
    }
  }
  return (
    <Fragment>
      {show ?
        <div className={classes.overlay}>
          <div className={classes.popup} style={style}>
            <div className={classes.popupTitleBar}>
              <h2 className={classes.popupTitle}>{title}</h2>
              <button className={classes.popupClose} onClick={handleClose}>&times;</button>
            </div>
            <div className={classes.popupContent}>
              {children}
            </div>
          </div>
        </div>
        : null}
    </Fragment>
  )
}

export default Popup;
