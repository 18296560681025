import React, {useState, Fragment, useEffect} from 'react';
import {Router, useNavigate} from '@reach/router';
import {useLocalStorage, writeStorage} from "@rehooks/local-storage";
import fetchBookingData from '../api/fetchBookingData';
import ServiceHub from './services/ServiceHub';
import CleaningServices from './services/CleaningServices';
import BookingData from '../types/BookingData';
import HelpServices from './services/HelpServices';
import OtherServices from './services/OtherServices';
import RequestServiceForm from "./services/RequestServiceForm";
import { LoadingOutlined} from '@ant-design/icons';
import FoodPage from "./FoodPage";
import FoodOrderFormPage from "./FoodOrderFormPage";

const ServicesOverviewPage = (props: { token: string }) => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [ bookingData, setBookingData ] = useState();

  const [ token ] = useLocalStorage('token');

  useEffect( () => {
    const fetch = async () => {
      const bookingResponse = await fetchBookingData(token || '');
      if (bookingResponse.error) {
        writeStorage('token', '');
        await navigate('/');
      } else {
        setBookingData(_ => bookingResponse);
        setLoading(_ => false);
      }
    };
    fetch();
  }, [token, navigate]);

  if (isLoading || bookingData === undefined) {
    return <Fragment>
      <LoadingOutlined style={{fontSize: "60px", margin: "40px auto", display: 'block'}}/>
    </Fragment>;
  }

  return (
    <Router>
      <ServiceHub
        path="/"
        bookingData={bookingData as unknown as BookingData}
      />
      <CleaningServices path="/cleaning" />
      <HelpServices path="/help" />
      <OtherServices path="/other" />
      <RequestServiceForm path='/new-request'/>
      <FoodPage path='/food-items' />
      <FoodOrderFormPage path='/food-order' />
    </Router>
  );
};

export default ServicesOverviewPage;
