import React, {Fragment, useContext, useEffect, useState} from 'react';
import BookingData from "../../types/BookingData";
import RequestTicket from "../../types/RequestTicket";
import fetchRequestsFromAPI from "../../api/fetchRequests";
import Heading from "../../components/Heading";
import getService from "./services";
import formatDistance from "date-fns/formatDistance";
import AppConfigContext from "../../utils/AppConfigContext";


export default function ({bookingData}: { bookingData: BookingData }) {
  const {showFrame} = useContext(AppConfigContext);
  const [requests, setRequests] = useState<RequestTicket[]>([]);

  useEffect(() => {
    const fetchRequests = async () => {
      const requestsResponse = await fetchRequestsFromAPI(bookingData.bookingCode || '');
      if (!requestsResponse.error && requestsResponse.requests) {
        setRequests((requestsResponse.requests as RequestTicket[]).filter(r => r.status !== 'closed'));
      }
    };
    fetchRequests();
    const interval = window.setInterval(async () => {
      fetchRequests();
    }, 5000);
    return () => {
      window.clearInterval(interval)
    }
  }, [bookingData.bookingCode])

  return (<Fragment>
    {requests.length > 0 &&
    <Fragment>
      <Heading level='h2' title='Active Requests'/>
      <div style={{
        display: 'flex', flexDirection: showFrame ? 'column' : 'row', flexWrap: 'wrap', alignItems: 'center',
        justifyContent: 'center'
      }}>
        {requests.map((request, index) =>
          <div className='CardItem' key={index}>
            <div>
                    <span style={{fontWeight: 700}}>
                      {getService(request.service).title}
                    </span>
            </div>
            <div>
                    <span style={{fontSize: '1rem', opacity: 0.8}}>
                      Status: <strong>{request.status}.</strong> Opened {formatDistance(new Date(request.openedAt), new Date())} ago.
                    </span>
            </div>
          </div>
        )}
      </div>
    </Fragment>
    }</Fragment>)

}
