import React, { ChangeEvent, Fragment } from 'react';
import './ImageInput.css';

const ImageUpload = ({
  name,
  label,
  capture,
  onSelectImage,
} : {
  name: string,
  label: string,
  capture: 'user' | 'environment',
  onSelectImage: (e: ChangeEvent<HTMLInputElement>) => void
}) =>
  <Fragment>
    <label htmlFor={name} className='ImageInputLabel'>
      {label}
    </label>
    <input
      type='file'
      id={name}
      name={name}
      accept='image/*'
      capture={capture}
      className='ImageInput'
      onChange={onSelectImage}
    />
  </Fragment>;

export default ImageUpload;
