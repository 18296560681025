import React, {Fragment, useState} from 'react';
import {useNavigate} from '@reach/router';
import {writeStorage} from '@rehooks/local-storage';
import RegistrationWizard from './registration/RegistrationWizard';

const RegistrationPage = ({ token } : { token: string }) => {
  const navigate = useNavigate();
  const [ tokenValue, setTokenValue ] = useState(token || '');
  const [ images, setImages ] = useState<Array<File|string|undefined>>([]);

  const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTokenValue(event.target.value);
  };

  const handleAddImages = (index: number, photo?: File | string) => {
    const newImages = [...images];
    newImages[index] = photo;
    setImages(_ => newImages);
  };

  const clearImages = () => {
    setImages(_ => []);
  };

  const onFinish = () => {
    console.log('Finished');
    writeStorage('token', tokenValue);
    writeStorage('isRegistered', true);
    navigate('/');
  };

  return (
    <Fragment>
      <RegistrationWizard
        onFinish={onFinish}
        token={tokenValue}
        images={images}
        handleTokenChange={handleTokenChange}
        handleAddImages={handleAddImages}
        clearImages={clearImages}
      />
    </Fragment>
  );
};

export default RegistrationPage;
