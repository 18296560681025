import React from 'react';
import './CardItem.css';
import contactPersonImage from '../assets/person.jpg';
import {PhoneFilled } from "@ant-design/icons/lib";


interface Props {
  onClick: () => void;
}

const ContactCard:React.FC<Props> = ({onClick}) =>
  <div className='CardItem CardItem--TwoColumns' onClick={()=> onClick()}>
    <div>
      <img
        src={contactPersonImage}
        alt='Jonathan, Guest Support Hero'
        style={{ height: 48, borderRadius: '99%', marginRight: '0.5rem' }}
      />
    </div>
    <div style={{flex: 1}}>
      <span>
        <span role='img' aria-label='hello'>👋</span> Hello, Jonathan is here to help you with any requests you might have.
      </span>
    </div>
    <div>
      <PhoneFilled style={{ fontSize: '30px', margin: '10px'}}  />
    </div>
  </div>;

export default ContactCard;
