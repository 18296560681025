import React, {useEffect, useState} from 'react';
import Popup from "./Popup";
import cameraIcon from "../assets/blockedcameraicon.svg";

interface Props {
  audio?: boolean;
  video?: boolean;
  closeStreamsOnCheck?: boolean;
}

const CameraCheck: React.FC<Props> = ({audio = true, video = true, closeStreamsOnCheck = true}) => {

  const [showBlockedCameraPopup, setShowBlockedCameraPopup] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (navigator.getUserMedia) {
      // @ts-ignore
      navigator.getUserMedia(
        // constraints
        {
          video: video,
          audio: audio
        },
        (localMediaStream) => {
          console.log('video success');
          console.log('closing streams');
          localMediaStream.getTracks().forEach(track => {
            track.stop();
          })

        },
        (err) => {
          console.error('failed ', err)
          setShowBlockedCameraPopup(true);
        }
      );
    }
  }, [setShowBlockedCameraPopup, video, audio])


  return (
    <Popup title='Microphone and camera access is blocked 🥺'
           style={{width: '40%'}}
           show={showBlockedCameraPopup}
           onClose={() => setShowBlockedCameraPopup(false)}
    >
      <p>avery needs access to your microphone and camera to provide a way for you to automatically checkin
        and invoke video calls with our staff.
      </p>
      <p>
        Please click the blocked camera icon <img src={cameraIcon} alt='blocked camera icon'/> in your browser's
        address bar (located at the end) and click allow.
      </p>
    </Popup>
  )
};

export default CameraCheck;
