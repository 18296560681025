import React, {ChangeEventHandler} from 'react';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';


const BookingCodeForm = ({
  handleSubmit,
  token,
  handleTokenChange
} : {
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  token: string,
  handleTokenChange: ChangeEventHandler
}) =>
  <form onSubmit={e => e.preventDefault()}>
    <TextInput
      name='bookingCode'
      label='Booking Code:'
      value={token}
      onChange={handleTokenChange}
      autoCapitalize="none"
    />
    <Button
      type='Primary'
      disabled={(token || '').length < 3}
      onClick={handleSubmit}
    >
      Continue →
    </Button>
  </form>;

export default BookingCodeForm;
