import React, {Fragment, useContext, useState} from 'react';
import {useNavigate} from '@reach/router';
import {writeStorage} from '@rehooks/local-storage';
import Heading from '../../components/Heading';
import HeroImage from '../../components/HeroImage';
import ServiceItem from '../../components/ServiceItem';
import ContactCard from '../../components/ContactCard';
import LocalVideo from '../../components/LocalVideo';
import hotelRoomImage from '../../assets/hotelRoom.jpg';
import BookingData from '../../types/BookingData';
import Button from '../../components/Button';
import FoodSection from "../../components/food/FoodSection";
import AppConfigContext from "../../utils/AppConfigContext";
import MyRequests from "./MyRequests";

const ServiceHub = ({bookingData}: { bookingData: BookingData, path: string }) => {
  const {showFrame} = useContext(AppConfigContext);

  const navigate = useNavigate();
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleVideoClose = () => {
    setIsVideoOpen(false);
  };

  return (
    <Fragment>
      <div>
        <Heading level='h1' title={`Welcome, ${bookingData.firstName}`}/>
        <HeroImage source={hotelRoomImage} label='The Lodge On The Cove'/>
        <Heading level='h2' title='Contact us'/>

        <ContactCard onClick={() => setIsVideoOpen(true)}/>
        <Heading level='h2' title='Need Something?'/>
        <div style={{
          display: 'flex', flexDirection: showFrame ? 'column' : 'row', flexWrap: 'wrap', alignItems: 'center',
          justifyContent: 'center'
        }}>
          <ServiceItem
            title='Cleaning Services'
            subTitle='Laundry, ironing, room cleaning and more.'
            onClick={() => navigate('/cleaning')}
          />
          <ServiceItem
            title='Request Help'
            subTitle='Maintenance, electronics, and other issues.'
            onClick={() => navigate('/help')}
          />
          <ServiceItem
            title='Other Requests'
            subTitle='Linens, pillows, towels, etc.'
            onClick={() => navigate('/other')}
          />
        </div>

        <MyRequests bookingData={bookingData}/>


        <Heading level='h2' title='Hungry?'/>
        <FoodSection/>
      </div>
      <LocalVideo
        onClose={handleVideoClose}
        guestName={`${bookingData.firstName} ${bookingData.lastName}`}
        show={isVideoOpen}
      />
      <div style={{marginTop: '2rem'}}>
        <Button
          onClick={() => {
            writeStorage('token', '');
            writeStorage('isRegistered', undefined);
            navigate('/');
          }}
          type='SecondarySmall'
        >
          Logout
        </Button>
      </div>
    </Fragment>
  )
};

export default ServiceHub;
