import React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { writeStorage } from '@rehooks/local-storage';

interface Props extends RouteComponentProps {
  token?: string;
}

const RedirectWithToken = (props: Props) => {
  writeStorage('token', String(props.token));
  return (
    <Redirect to='/' noThrow />
  );
};

export default RedirectWithToken;
