import React from 'react';

const errorStyle = {
  color: 'crimson',
  fontWeight: 700,
};

const RegistrationError = (
  { bookingCodeError, facialCheckInError } :
  { bookingCodeError: boolean, facialCheckInError: boolean }
) => {
  if (bookingCodeError) {
    return (
      <p style={errorStyle}>
        There was en error with your booking code, please review the entered information and try again.
      </p>
    );
  } else if (facialCheckInError) {
    return (
      <p style={errorStyle}>
        There was en error matching your facial id. Try a different angle for your photos and make sure you have good lighting.
      </p>
    );
  } else {
    return null;
  }
};

export default RegistrationError;
