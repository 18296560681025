import {ServiceKey} from "../pages/services/services";

const openServiceTicket = async (token: string, serviceId: ServiceKey) => {
  return await fetch(
    `https://us-central1-avery-prod.cloudfunctions.net/guestBooking/front-desk`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bookingCode: token,
        projectCode: process.env.REACT_APP_ORGANIZATION_CODE,
        service: serviceId,
      })
    }
  ).then((response) => {
      return { error: response.status !== 200 };
    })
    .catch((e) => {
      return { error: true };
    });
};

export default openServiceTicket;
