import React from 'react';
import addDays from 'date-fns/addDays';
import formatDate from 'date-fns/format';

const RegistrationWizardHeader = ({ step } : { step: number }) => {
  switch (step) {
    case 5:
      return (
        <div>
          <h1>You’re All Set!</h1>
          <p>Your registration and check-in is completed. Enjoy your stay!</p>
        </div>
      );
    case 4:
      return (
        <div>
          <h3>We need to pre-authorize your card for incidentals!</h3>
        </div>
      );
    case 3:
      return (
        <div>
          <h1>Easy Check-in</h1>
          <p>Take a selfie and a photo of your ID or passport for a seamless check-in experience.</p>
        </div>
      );
    case 2:
      return (
        <div>
          <h1>Your stay with us</h1>
          <p>You booked a stay with us from <br/><b>{formatDate(addDays(new Date(), 7), 'MM-dd')} to {formatDate(addDays(new Date(), 12), 'MM-dd')}</b></p>
          <p>Your room is:</p>
        </div>
      );
    case 1:
    default:
      return(
        <div>
          <h1>Registration</h1>
          <p>Welcome! Let’s get you all set up.</p>
        </div>
      );
  }
};

export default RegistrationWizardHeader;
