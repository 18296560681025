import React, {useState} from 'react';
import Button from "../components/Button";
import {Redirect, RouteComponentProps, useNavigate} from '@reach/router';
import MenuItem from "../components/food/MenuItem";
import {FoodItem} from "./FoodPage";

const FoodOrderFormPage = (props: RouteComponentProps) => {
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
  const [ hasError, setHasError ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);

  if (
    props.location === undefined ||
    props.location.state === undefined
  ) {
    return <Redirect to='/' noThrow/>
  }

  const menuItem = (props.location?.state as { menuItem: FoodItem }).menuItem;

  if (!menuItem) {
    return <Redirect to='/' noThrow />
  }

  const requestService = async () => {
    setLoading(_ => true);
    setHasError(_ => false);
    const result = {success: true, error: false};
    if (result.error) {
      setHasError(_ => true);
    } else {
      setShowSuccess(_ => true);
    }
    setLoading(_ => false);
  };

  if (showSuccess) {
    return (
      <div >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <MenuItem item={menuItem} />
        </div>
        <p style={{
          fontWeight: 700,
        }}>
          Success! Your order has been forwarded to our staff.
        </p>
        <Button
          type='Primary'
          onClick={() => navigate('/food-items')}
        >
          Back to Menu
        </Button>
      </div>
    );
  }

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <MenuItem item={menuItem} />
      </div>
      <Button
        type='Primary'
        onClick={() => requestService()}
      >
        Order this item
      </Button>

      <Button
        type='Secondary'
        onClick={() => navigate('/food-items')}
        disabled={loading}
      >
        ← Back
      </Button>
      {hasError &&
        <p style={{
          color: 'crimson',
          fontWeight: 700
        }}>
          Ooops, there was an error submitting your request, please try again!
        </p>
      }
    </div>
  );
};

export default FoodOrderFormPage;
