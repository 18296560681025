import React, {useEffect, useState, Fragment} from 'react';
import firebase from '../firebase';
import MenuItem from "../components/food/MenuItem";
import {RouteComponentProps, useNavigate} from "@reach/router";
import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import map from 'lodash/fp/map';
import Button from "../components/Button";
import foodClasses from '../components/food/FoodSection.module.css';


export interface FoodItem {
  id?: string;
  description?: string;
  image: string;
  price?: string;
  title: string;
  type?: string;
}

export interface FoodItemGroup {
  group: string;
  items: FoodItem[];
}

const foodGroupLabels: Map<string, string> = new Map([
  ['mainDish', 'Main'],
  ['appetizer', 'Appetizer'],
  ['dessert', 'Dessert'],
  ['beverage', 'Beverage'],
]);

const FoodPage = (props: RouteComponentProps) => {
  const navigate = useNavigate();
  const [foodItems, setFoodItems] = useState<FoodItemGroup[]>([])

  useEffect(() => {
    firebase.database().ref(`/conferences/${process.env.REACT_APP_ORGANIZATION_CODE}/foodMenu`).once('value').then(snapshot => {
      const foodItems = flow(groupBy('type'), values, map(items => ({
        group: items[0].type,
        items: items
      } as FoodItemGroup)))(Object.entries(snapshot.val()).map((item: [string, any]) => ({id: item[0], ...item[1]} as FoodItem))) as FoodItemGroup[];
      setFoodItems(foodItems);
    }).catch(e => console.error(e));
  }, [])

  return (
    <div style={{margin: '0 auto', textAlign: 'center'}}>
      <Button
        type='Secondary'
        onClick={() => navigate('/')}
      >
        ← Back
      </Button>
      {foodItems.map(item => <Fragment key={item.group}>
        <h2>{foodGroupLabels.get(item.group)}</h2>
        <div className={foodClasses.FoodItems} style={{flexWrap: 'wrap'}}>
          {item.items.map((menuItem, index) =>
            <MenuItem key={index} item={menuItem}
                      onClick={() => navigate('/food-order', {state: {menuItem: menuItem}})}/>)}
        </div>
      </Fragment>)}
    </div>
  )
}

export default FoodPage;
