import React from 'react';
import ServiceItem from "../../components/ServiceItem";
import Button from "../../components/Button";
import {useNavigate} from "@reach/router";
import {ServiceKey} from "./services";
import getService from "./services";

const services: Array<ServiceKey> = [
  'PERDICO/ROOM_SERVICE/TOILETRIES',
  'PERDICO/ROOM_SERVICE/TOWELS',
  'PERDICO/ROOM_SERVICE/PILLOWS',
  'PERDICO/ROOM_SERVICE/OTHER'
];

const HelpServices = ({ path } : { path: string }) => {
  const navigate = useNavigate();
  return (
    <div>
      <h2>Other Requests</h2>
      {services.map(service =>
        <ServiceItem
          key={service}
          title={getService(service).title}
          subTitle={getService(service).subTitle}
          onClick={() =>
            navigate('/new-request', { state: { serviceId: service }})
          }
        />
      )}

      <Button
        type='Secondary'
        onClick={() => navigate('/')}
      >
        ← Back
      </Button>
    </div>
  );
};

export default HelpServices;
