export type ServiceKey =
  'PERDICO/ROOM_SERVICE/ROOM_CLEANING' |
  'PERDICO/ROOM_SERVICE/DRY_CLEANING' |
  'PERDICO/ROOM_SERVICE/IRONING' |
  'PERDICO/ROOM_SERVICE/OTHER' |
  'PERDICO/MAINTENANCE/GENERAL' |
  'PERDICO/MAINTENANCE/OTHER' |
  'PERDICO/ROOM_SERVICE/TOILETRIES' |
  'PERDICO/ROOM_SERVICE/TOWELS' |
  'PERDICO/ROOM_SERVICE/PILLOWS' |
  'PERDICO/ROOM_SERVICE/OTHER' |
  'PERDICO/ROOM_SERVICE/FOOD/ORDER';

interface ServiceItem {
  title: string;
  subTitle: string;
  hasNote?: boolean;
}

const serviceMap = {
  'PERDICO/ROOM_SERVICE/ROOM_CLEANING': {
    title: 'Room Cleaning',
    subTitle: '',
  },
  'PERDICO/ROOM_SERVICE/DRY_CLEANING': {
    title: 'Dry Cleaning',
    subTitle: '',
  },
  'PERDICO/ROOM_SERVICE/IRONING': {
    title: 'Ironing',
    subTitle: '',
  },
  'PERDICO/ROOM_SERVICE/TOILETRIES': {
    title: 'Toiletries',
    subTitle: '',
  },
  'PERDICO/ROOM_SERVICE/TOWELS': {
    title: 'Towels',
    subTitle: 'Standard bath and hand towels',
  },
  'PERDICO/ROOM_SERVICE/PILLOWS': {
    title: 'Pillows',
    subTitle: '',
  },
  'PERDICO/ROOM_SERVICE/OTHER': {
    title: 'Other Request',
    subTitle: 'We’ll contact you to get additional info.',
    hasNote: true,
  },
  'PERDICO/MAINTENANCE/GENERAL': {
    title: 'Maintenance',
    subTitle: 'For issues with room facilities.',
    hasNote: true,
  },
  'PERDICO/MAINTENANCE/OTHER': {
    title: 'Other Maintenance Request',
    subTitle: 'Leave us a note what needs to be done.',
    hasNote: true,
  },
  'PERDICO/ROOM_SERVICE/FOOD/ORDER': {
    title: 'Food Order',
    subTitle: '',
    hasNote: false,
  }
};

export default (key: ServiceKey) => serviceMap[key] as ServiceItem;

export const getServiceDescription = (key: ServiceKey) => {
  switch (key) {
    case 'PERDICO/ROOM_SERVICE/ROOM_CLEANING':
      return 'We’ll schedule a convenient time for cleaning staff to come to your room.';
    case 'PERDICO/ROOM_SERVICE/DRY_CLEANING':
      return 'We’ll schedule a pickup time for your clothes.';
    case 'PERDICO/ROOM_SERVICE/IRONING':
      return 'We’ll schedule a pickup time for your clothes.';
    case 'PERDICO/ROOM_SERVICE/OTHER':
      return 'Frontdesk will call you as soon as possible.';
    case 'PERDICO/MAINTENANCE/GENERAL':
      return 'Need assistance with the room facilities? Frontdesk will call you to arrange maintenance as soon as possible.';
    case 'PERDICO/MAINTENANCE/OTHER':
      return 'Frontdesk will call you as soon as possible.';
    case 'PERDICO/ROOM_SERVICE/TOILETRIES':
      return 'Additional toiletries will be delivered to your room soon.';
    case 'PERDICO/ROOM_SERVICE/TOWELS':
      return 'Additional bath and face towels will be delivered to your room soon.';
    case 'PERDICO/ROOM_SERVICE/PILLOWS':
      return 'Extra pillows will be delivered to your room soon.';
    default:
      return 'Frontdesk will call you as soon as possible.';
  }
};
