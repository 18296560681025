import React, {useEffect, useRef, useState, Fragment} from 'react';


import {
  DefaultMeetingSession,
  MeetingSessionConfiguration,
  ConsoleLogger,
  DefaultDeviceController,
  LogLevel,
  VideoTileState, AudioVideoFacade
} from 'amazon-chime-sdk-js';
import ProgressLoader from "./ProgressLoader";
import Popup from "./Popup";

import classes from './LocalVideo.module.css';
import CameraCheck from "./CameraCheck";

const create_tickets_endpoint = `https://9brg5xk5e2.execute-api.us-east-1.amazonaws.com/Prod/tickets`;


const initiateCall = (name: string) => {
  return window.fetch(
    encodeURI(`${create_tickets_endpoint}?CustomerName=${name}`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Access-Control-Request-Method': 'OPTIONS',
      }
    },
  )
    .then(res => res.json())
    .then(async result => {

        const ticket = result;
        console.log(`Created Ticket: ${JSON.stringify(ticket, null, 2)}`);

        const logger = new ConsoleLogger('SDK', LogLevel.INFO);
        const session = new DefaultMeetingSession(
          new MeetingSessionConfiguration(
            ticket.Meeting,
            ticket.CustomerAttendee,
          ),
          logger,
          new DefaultDeviceController(logger),
        );

        const firstAudioDeviceId = (await session.audioVideo.listAudioInputDevices())[0].deviceId;
        await session.audioVideo.chooseAudioInputDevice(firstAudioDeviceId);

        const firstVideoDeviceId = (await session.audioVideo.listVideoInputDevices())[0].deviceId;
        await session.audioVideo.chooseVideoInputDevice(firstVideoDeviceId);

        return session.audioVideo
      },
      (error) => {
        console.error('Error processing response ', error);
      })
    .catch(err => console.error('Error ', err));

}

interface Props {
  onClose: () => void;
  guestName: string;
  show: boolean;
}

const LocalVideo: React.FC<Props> = ({onClose, guestName, show}) => {

  const audioElementRef = useRef(null);

  const [ready, setReady] = useState(false);
  const videoElementLocalRef = useRef(null);
  const videoElementRemoteRef = useRef(null);

  const [chimeAudioVideo, setChimeAudioVideo] = useState<AudioVideoFacade | undefined>(undefined);

  useEffect(() => {
    console.log('Props ',setReady, audioElementRef, guestName, onClose, videoElementRemoteRef, videoElementLocalRef, show);
    if (!show || !setReady || !audioElementRef || !guestName || !onClose || !videoElementRemoteRef || !videoElementLocalRef) return;
    const start = async () => {
      const initializedChimeAudioVideo = await initiateCall(guestName);
      if (!initializedChimeAudioVideo) {
        onClose();
        return;
      }


      if (audioElementRef && audioElementRef.current) {
        initializedChimeAudioVideo.bindAudioElement((audioElementRef.current as unknown) as HTMLAudioElement);
      }

      initializedChimeAudioVideo?.addObserver({
        audioVideoDidStart: () => {
          console.log('we are ready to start local video');
          setReady(true);
          initializedChimeAudioVideo?.startLocalVideoTile();
        },
        videoTileDidUpdate: (tileState: VideoTileState): void => {
          if (!tileState.tileId) return;

          try {
            if (tileState.localTile) {
              initializedChimeAudioVideo?.bindVideoElement(
                tileState.tileId,
                (videoElementLocalRef.current as unknown) as HTMLVideoElement
              )
            } else {
              initializedChimeAudioVideo?.bindVideoElement(
                tileState.tileId,
                (videoElementRemoteRef.current as unknown) as HTMLVideoElement
              )
            }
            console.log('we are ready ', tileState.active, tileState);
            setReady(true);
          } catch (err) {
            console.warn('Error initializing all video elements. ', err);
            setReady(false);
          }
        }
      });
      initializedChimeAudioVideo?.start();
      setChimeAudioVideo(initializedChimeAudioVideo);
    }
    start();
  }, [audioElementRef, setReady, guestName, onClose, videoElementRemoteRef, videoElementLocalRef, show]);

  const handleClose = () => {
    try {
      chimeAudioVideo?.stop();
      chimeAudioVideo?.stopLocalVideoTile();
    } catch (err) {
      console.error('Stop videos error ', err);
    }
    setChimeAudioVideo(undefined);
    onClose();
  }


  return (
    <Fragment>
      <CameraCheck audio={false} closeStreamsOnCheck={false}/>


      <Popup title='👋' onClose={handleClose} show={show}>
        <div style={{display: ready ? 'block' : 'none'}}>
          <audio ref={audioElementRef} className={classes.videoAudioContent}/>
          <video ref={videoElementLocalRef} className={classes.videoLocalContent}/>
          <video ref={videoElementRemoteRef} className={classes.videoRemoteContent}/>
        </div>
        {!ready && <ProgressLoader/>}
      </Popup>
    </Fragment>
  )

}

export default LocalVideo;
