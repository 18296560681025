import React from 'react';
import './Button.css';

const Button = ({
  onClick,
  children,
  type,
  disabled,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  children: React.ReactChild,
  type: 'Primary' | 'Secondary' | 'SecondarySmall',
  disabled?: boolean
}) =>
  <button
    onClick={onClick}
    type='button'
    className={`Button Button${type}`}
    disabled={disabled}
  >
    {children}
  </button>;

export default Button;
