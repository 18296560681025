import React from 'react';

import './progress_loader.css';

// const cx = classNames.bind(styles);

const ProgressLoader: React.FC = () => {
  return (
    <div className='loader'>Loading...</div> //{cx('loader')}
  )
}

export default ProgressLoader;
