import React from 'react';
import './CardItem.css';

const ServiceItem = (props: { title: string, subTitle: string, hasNote?: boolean, onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }) =>
  <div className='CardItem' onClick={props.onClick}>
    <div>
      <span style={{fontWeight: 700}}>
        {props.title}
      </span>
    </div>
    <div>
      <span style={{fontSize: '0.8rem', opacity: 0.8}}>
        {props.subTitle}
      </span>
    </div>
  </div>;

export default ServiceItem;
