import React, {ChangeEventHandler, useState} from 'react';
import BookingCodeForm from './BookingCodeForm';
import PhotoCollectionForm from './PhotoCollectionForm';
import RegistrationError from './RegistrationError';
import RegistrationWizardHeader from './RegistrationWizardHeader';
import facialCheckIn from '../../api/facialCheckIn';
import fetchBookingData from '../../api/fetchBookingData';
import Button from '../../components/Button';
import CardAuthorization from "./CardAuthorization";
import { parse } from "query-string"
import StayInformation from "./StayInformation";

const RegistrationWizard = ({
                              onFinish,
                              token,
                              images,
                              handleTokenChange,
                              handleAddImages,
                            clearImages,}: {
  onFinish: () => void,
  token: string,
  images: Array<File | string | undefined>,
  handleTokenChange: ChangeEventHandler,
  handleAddImages: (index: number, photo?: File) => void,
  clearImages: () => void,
}) => {
  const [step, setStep] = useState((parse(window.location.search, {parseNumbers: true}).step as any) as number || 1);
  const [isLoading, setLoading ] = useState(false);
  const [bookingCodeError, setBookingCodeError] = useState(false);
  const [facialCheckInError, setFacialCheckInError] = useState(false);

  const submitForm = async () => {
    setBookingCodeError(false);
    setFacialCheckInError(false);
    setLoading(true);
    const bookingResponse = await fetchBookingData(token || '');
    if (bookingResponse.error) {
      setStep(1);
      setBookingCodeError(true);
      setLoading(false);
    } else {
      const facialCheckInResponse = await facialCheckIn(token, images as Array<File|string>);
      setLoading(false);
      if (facialCheckInResponse.error) {
        setStep(3);
        setFacialCheckInError(true);
        clearImages();
      } else {
      setStep(4);
      }
    }
  };

  return (
    <div style={{overflowX: 'hidden'}}>

      <RegistrationWizardHeader step={step}/>
      <RegistrationError
        bookingCodeError={bookingCodeError}
        facialCheckInError={facialCheckInError}
      />

      {step === 1 &&
      <BookingCodeForm
        handleSubmit={() => token.length > 3 ? setStep(s => s + 1) : undefined}
        token={token}
        handleTokenChange={handleTokenChange}
      />}
      {step === 2 &&
        <StayInformation
          handleSubmit={() => setStep(s => s + 1)}
        />
      }
      {step === 3 &&
      <PhotoCollectionForm
        images={images}
        handleAddImages={handleAddImages}
        handleSubmit={() => (images[0] && images[1] ) ? submitForm() : undefined}
      isLoading={isLoading}
        />
      }
      {step === 4 &&
        <div>
          <CardAuthorization onSuccess={onFinish}/>
        </div>
      }

      {step === 5 &&
      <div>
        <Button onClick={onFinish} type='Primary'>
          View Amenities & Services
        </Button>
      </div>
      }
      {step !== 1 &&
      <div style={{marginTop: '2rem'}}>
        <Button
          onClick={onFinish}
          type='SecondarySmall'
        >
          Skip
        </Button>
      </div>
      }
    </div>
  );
};

export default RegistrationWizard;
