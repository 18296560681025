import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useLocalStorage } from '@rehooks/local-storage';

import RegistrationPage from './RegistrationPage';
import ServicesOverviewPage from './ServicesOverviewPage';

interface Props extends RouteComponentProps {}

const GuestHome = (props: Props) => {
  const [ token ] = useLocalStorage('token');
  const [ isRegistered ] = useLocalStorage('isRegistered');

  if (isRegistered && token) {
    return <ServicesOverviewPage token={token} />
  }

  return <RegistrationPage token={token || ''} />;
};

export default GuestHome;
