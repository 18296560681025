import React from 'react';

const HeroImage = (props: { source: string, label: string }) =>
  <div style={{
    height: 160,
    background: `url(${props.source}) no-repeat center center`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    alignContent: 'end',
    borderRadius: '4px'
  }}>
    <span style={{
      fontSize: '1.4rem',
      color: '#fff',
      paddingLeft: '1rem',
      paddingBottom: '1rem',
    }}>{props.label}</span>
  </div>;

export default HeroImage;
