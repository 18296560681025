import React, {useState} from 'react';
import {Router} from '@reach/router';
import classes from './App.module.css';
import GuestHome from './pages/GuestHome';
import RedirectWithToken from './pages/RedirectWithToken';
import iPhoneFrame from './assets/iPhoneFrame.png';
import AppConfigContext from './utils/AppConfigContext';
import clsx from 'clsx';
import AveryLogo from './assets/avery-logo.svg';
import FoodPage from "./pages/FoodPage";


const isMacOrAndroid = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const applePlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'iPhone', 'iPad', 'iPod'];

  return applePlatforms.indexOf(platform) !== -1 || /Android/.test(userAgent);
}


const App = () => {
  const [showFrame, toggleShowFrame] = useState(false);
  return (
    <AppConfigContext.Provider value={{showFrame}}>
      <button
        className={classes.iPhoneFrameButton}
        onClick={() => toggleShowFrame(_ => !_)}
      >Toggle mobile frame
      </button>
      <div
        className={showFrame ? classes.iPhoneFrame : ''}
        style={showFrame ? {
          backgroundImage: `url(${iPhoneFrame})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          padding: '24px 42px 0px 42px',
          overflow: 'hidden',
          height: '770px'
        } : {}}
      >
        <div className={clsx(classes.container, isMacOrAndroid() ? '' : classes.nicescrollbar)}>
          <div>
            <img src={AveryLogo} alt='avery logo' className={classes.logoHead}
                 style={showFrame ? {marginTop: '20px'} : {}}/>
          </div>
          <Router>
            <FoodPage path='/food'/>
            <RedirectWithToken path='/t/:token'/>
            <GuestHome path='*'/>
          </Router>
        </div>
      </div>


    </AppConfigContext.Provider>
  );
};

export default App;
