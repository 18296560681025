import React from 'react';

const Heading = ({ title, level } : { title: string, level: string }) => {
  switch (level) {
    case 'h6':
      return <h6>{title}</h6>;
    case 'h5':
      return <h5>{title}</h5>;
    case 'h4':
      return <h4>{title}</h4>;
    case 'h3':
      return <h3>{title}</h3>;
    case 'h2':
      return <h2>{title}</h2>;
    case 'h1':
    default:
      return <h1>{title}</h1>;
  }
};


export default Heading;
