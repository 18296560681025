import React, {useState} from 'react';
import Button from "../../components/Button";
import {Redirect, RouteComponentProps, useNavigate} from '@reach/router';
import getService, {ServiceKey, getServiceDescription} from './services';
import {useLocalStorage} from "@rehooks/local-storage";
import openServiceTicket from '../../api/openServiceTicket';
import TextInput from "../../components/TextInput";

const RequestServiceForm = (props: RouteComponentProps) => {
  const navigate = useNavigate();
  const [ token ] = useLocalStorage('token');
  const [ loading, setLoading ] = useState(false);
  const [ hasError, setHasError ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);

  const [additionalNote, setAdditionalNote] = useState<string>();

  if (
    props.location === undefined ||
    props.location.state === undefined
  ) {
    return <Redirect to='/' noThrow />
  }

  const serviceId = (props.location.state as { serviceId: ServiceKey }).serviceId;
  const service = getService(serviceId);

  if (!service) {
    return <Redirect to='/' noThrow />
  }

  const requestService = async () => {
    setLoading(_ => true);
    setHasError(_ => false);
    const result = await openServiceTicket(token || '', serviceId);
    if (result.error) {
      setHasError(_ => true);
    } else {
      setShowSuccess(_ => true);
    }
    setLoading(_ => false);
  };

  if (showSuccess) {
    return (
      <div>
        <h2>{service.title}</h2>
        <p>{service.subTitle}</p>
        <p style={{
          fontWeight: 700,
        }}>
          Success! Your request has been forwarded to our staff.
        </p>
        <Button
          type='Primary'
          onClick={() => navigate('/')}
        >
          Back to Menu
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h2>{service.title}</h2>
      <p>{service.subTitle}</p>
      {service.hasNote &&
      <div>
        <TextInput name='additionalNote'
                   label='Additional note:'
                   value={additionalNote}
                   onChange={(event) => {
                     setAdditionalNote(event.target.value)
                   }}/>
      </div>
      }
      <p style={{ fontSize: '0.9rem', opacity: 0.75}}>
        {getServiceDescription(serviceId)}
      </p>
      <Button
        type='Primary'
        onClick={() => requestService()}
      >
        Request Service
      </Button>

      <Button
        type='Secondary'
        onClick={() => navigate('/')}
        disabled={loading}
      >
        ← Back
      </Button>
      {hasError &&
        <p style={{
          color: 'crimson',
          fontWeight: 700
        }}>
          Ooops, there was an error submitting your request, please try again!
        </p>
      }
    </div>
  );
};

export default RequestServiceForm;
