import React, {Fragment, useContext, useRef, useState} from 'react';
import WebCam from 'react-webcam';
import ImageUpload from '../../components/ImageInput';
import ImagePreview from '../../components/ImagePreview';
import Button from '../../components/Button';
import AppConfigContext from '../../utils/AppConfigContext';
import CameraCheck from "../../components/CameraCheck";
import clsx from 'clsx';
import {LoadingOutlined} from '@ant-design/icons';

import classes from './PhotoCollectionForm.module.css'


const getWebCamButtonConfig = (images: Array<any>) => {
  if (images[0] === undefined) {
    return {index: 0, label: 'Take Face Photo'};
  } else {
    return {index: 1, label: 'Scan Document Photo'};
  }
};

const PhotoCollectionForm = ({
                               images,
                               handleAddImages,
                               handleSubmit,
                               isLoading,
                             }: {
  images: Array<File | string | undefined>,
  handleAddImages: (index: number, photo?: File) => void,
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  isLoading: boolean,
}) => {
  const treatAsMobile = window.outerWidth < 900;
  const {showFrame} = useContext(AppConfigContext);
  const webCamRef = useRef(null);

  const [streamReady, setStreamReady] = useState(false);

  const capture = React.useCallback(
    (index: number) => {
      if (webCamRef !== null && webCamRef.current !== null) {
        // @ts-ignore
        const imageSrc = webCamRef.current.getScreenshot();
        handleAddImages(index, imageSrc);
      }
    },
    [webCamRef, handleAddImages]
  );

  const onStreamReady = () => {
    console.log('stream is ready');
    setStreamReady(true)
  }

  return (
    <Fragment>
      <CameraCheck audio={false}/>
      <form onSubmit={e => e.preventDefault()}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '1rem'
        }}>
          <ImagePreview image={images[0]}/>
          <ImagePreview image={images[1]} variant='rectangle'/>
        </div>
        {treatAsMobile ?
          <Fragment>
            <ImageUpload
              label='Take Face Photo'
              name='facePhoto1'
              capture='user'
              onSelectImage={(e) => handleAddImages(0, e.target.files ? e.target.files[0] : undefined)}
            />
            <ImageUpload
              label='Scan Document Photo'
              name='documentPhoto'
              capture='environment'
              onSelectImage={(e) => handleAddImages(2, e.target.files ? e.target.files[0] : undefined)}
            />
          </Fragment> :
          <div style={{marginBottom: '2rem'}}>
            {images[0] && images[1] ?
              null :
              <Fragment>
                <div className={clsx(classes.VideoPreviewFrame, images[0] !== undefined ?
                  showFrame ? classes.RectSmall : classes.RectNormal :
                  showFrame ? classes.BoxSmall : classes.BoxNormal)}>
                  <WebCam
                    audio={false}
                    mirrored
                    videoConstraints={{
                      height: 720,
                      width: 1280
                    }}
                    ref={webCamRef}
                    className={clsx(showFrame ? classes.Small : classes.Normal)}
                    style={images[0] ? {} : {left: showFrame ? '-35%': '-35%'}}
                    screenshotFormat='image/jpeg'
                    screenshotQuality={0.7}
                    onUserMedia={onStreamReady}
                  />
                </div>
                <Button onClick={() => capture(getWebCamButtonConfig(images).index)} type='Primary' disabled={!streamReady}>
                  {getWebCamButtonConfig(images).label}
                </Button>
              </Fragment>
            }
          </div>
        }

        {images[0] && images[1] &&
        <Button
          type='Primary'
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Continue →
        </Button>}

        {isLoading &&
        <LoadingOutlined style={{fontSize: "60px", margin: "40px auto", display: 'block'}}/>
        }
      </form>
    </Fragment>
  );
};

export default PhotoCollectionForm;
