import React, {useContext} from "react";

import classes from './MenuItem.module.css';
import {FoodItem} from "../../pages/FoodPage";
import AppConfigContext from "../../utils/AppConfigContext";

interface Props {
  onClick?: () => void;
  item: FoodItem;
  small?: boolean;
}

const MenuItem: React.FC<Props> = ({item, small, onClick}) => {
  const {showFrame} = useContext(AppConfigContext);

  return (<div className={classes.Container} style={{width: small ? 160 : 280}} onClick={onClick}>
    <div>
      <img
        className={classes.ImageContainer}
        src={item.image}
        alt={item.title}
        style={{width: '100%', height: showFrame ? small ? 80 : 150 : small ? 120 : 220}}
      />
    </div>
    <div style={{fontSize: small ? 12 : 14}}>
      <h3>{item.title}</h3>
      {!small &&
      <p>
        {item.description}
      </p>
      }
      {item.price &&
      <p><strong>Price: </strong> {item.price}</p>
      }
    </div>
  </div>)
}

export default MenuItem;
