import firebase from '../firebase';

const dataURItoBlob = (dataURI: string) => {
  let binary = atob(dataURI.split(',')[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    // @ts-ignore
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
};

const facialCheckIn = async (token: string, images: Array<File|string>) => {
  try {
    const faceFormData = new FormData();
    const documentFormData = new FormData();
    faceFormData.append('username', token);
    faceFormData.append('face', typeof images[0] === 'string' ? dataURItoBlob(images[0]) : images[0]);
    documentFormData.append('username', token);
    documentFormData.append('face', typeof images[1] === 'string' ? dataURItoBlob(images[1]) : images[1]);

    const faceResponse =  await fetch(
      'https://api.averyapp.com/rekognition/create',
      {
        method: 'POST',
        body: faceFormData
      })
      .then((response) => {
        return response.json();
      })
      .catch(() => ({ error: true }));

    if (!faceResponse.error) {
      const documentResponse = await fetch(
        'https://api.averyapp.com/rekognition/search',
        {
          method: 'POST',
          body: documentFormData
        })
        .then((response) => {
          return response.json();
        })
        .catch(() => ({ error: true }));
      const searchedFaceConfidence = documentResponse.data ? documentResponse.data.SearchedFaceConfidence as number : 0;
      if (documentResponse.error && searchedFaceConfidence < 90) {
        return { error: true};
      } else {
        firebase.storage()
          .ref(`@${process.env.REACT_APP_ORGANIZATION_CODE}/guests/${token}/photoid.jpg`)
          .put(typeof images[1] === 'string' ? dataURItoBlob(images[1]) : images[1])
          .then(snapshot => {console.log('success posting file', snapshot.totalBytes/1024)})
          .catch(error => console.log('error uploading photo id', error));

        return { error: false };
      }

    } else {
      return { error: true };
    }
  } catch (e) {
    console.error(e);
    return { error: true };
  }
};

export default facialCheckIn;
