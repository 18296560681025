import React, {useEffect, useState} from 'react';

const ImagePreview = ({
  image, variant = 'circle'
} : {
  image?: File | string,
  variant?: 'square' | 'circle' | 'rectangle',
}) => {
  const [ imageUrl, setImageUrl ] = useState('');
  useEffect(() => {
    if (typeof image === 'string') {
      setImageUrl(_ => image);
    } else {
      setImageUrl(_ => image ? URL.createObjectURL(image) : '');
    }
  }, [ image ]);

  return (
    <div style={{
      width: ['square', 'circle'].includes(variant) ? 72 : 128,
      height: 72,
      borderRadius: variant === 'circle' ? 99 : 0,
      backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#eeeeee88',
      backgroundSize: 'cover',
      backgroundPositionX: variant === 'circle'? '35%' : variant === 'square' ? '12.5%' : 0,
      display: 'flex',
    }}>
      {' '}
    </div>
  );
};

export default ImagePreview;
