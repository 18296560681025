import React, {ChangeEventHandler} from 'react';
import './TextInput.css';

const TextInput = ({
                     name,
                     label,
                     value,
                     defaultValue,
                     onChange,
                     autoCapitalize,
                   }: {
  name: string,
  label: string,
  value?: string,
  defaultValue?: string,
  onChange: ChangeEventHandler<HTMLInputElement>
  autoCapitalize?: string

}) =>
  <div className="TextInputWrapper">
    <label className='TextInputLabel' htmlFor={name}>
      {label}
    </label>
    <input
      id={name}
      name={name}
      type='text'
      className='TextInput'
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      aria-autocomplete='none'
      autoComplete='none'
      autoCapitalize={autoCapitalize || 'words'}
    />
  </div>


export default TextInput;
