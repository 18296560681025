import React, {Fragment} from 'react';
import Button from '../../components/Button';
import hotelRoomImage from "../../assets/hotelRoom.jpg";
import hotelRoomUpgradeImage from "../../assets/hotelRoomUpgrade.jpg";
import HeroImage from "../../components/HeroImage";


const StayInformation = ({
                           handleSubmit,
                         }: {
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}) =>

  <Fragment>
    <HeroImage source={hotelRoomImage} label='Premier Studio King'/>
    <p>Special upgrade available for you today to - King Suite</p>
    <p>For only $23 per night more</p>
    <HeroImage source={hotelRoomUpgradeImage} label='King Suite'/>
    <Button
      type='Primary'
      onClick={handleSubmit}
    >
      Get the upgrade
    </Button>
    <Button
      type='Secondary'
      onClick={handleSubmit}
    >
      Keep existing rom
    </Button>
  </Fragment>


export default StayInformation;
